.button {
  font-weight: 600;
  background: var(--button-color);
  border-radius: 5px;
  padding: 20px 25px;
  color: white;
  text-decoration: none;
  display: inline-block;
  transition: all 0.1s ease-out;
}

  .button:hover {
    background: var(--button-color-hover);

    .arrowRight {

        transform: translateX(2px);
      }

     
  }

.buttonLight {
  border-radius: 5px;
  border: 1px solid #1B67B1;
  padding: 20px 25px;
  background: #FFF;
  text-decoration: none;
  display: inline-block;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  color: #1B67B1;
  text-align: center;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.12px;
  transition: all 0.3s ease-out;

  &:hover {
    .arrowRight {
        transform: translateX(2px);
      }
  }

}

.buttonLight:hover {
  
}


.arrowRight {
  width: 14px;
  padding-left: 10px;
  transition: 0.3s all ease;
}