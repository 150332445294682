.applyPage {
    .hero {
        
        opacity: 0;
        // transform: translateY(-50px);
        transition: opacity 0.5s;
        // z-index: -1;

        .left {
            p {
                font-size: 22px;
                line-height: 150%;    
            }

            a {
                margin-bottom: 10px;
            }


        }

        .right {
            z-index: -1;
        }

        .blob1 {
            position: absolute;
            width: 250px;
            top: 20px;
            }
    
        .blob2 {
            position: absolute;
            width: 250px;
            top: 210px;
        }
    
        .bigBlob {
            position: absolute;
            height: 600px;
            top: -100px;
            right: -60px;
            z-index: -231;
            fill: var(--medium-color);
        }

        .button {
            margin-right: 10px;
        }
    }

    a {
        text-decoration: none;
    }

    .splashWave {
        position: absolute;
        top: 150px;
        left: 0px;
        z-index: -2;
        width: 100%;
        fill: var(--light-color);
    }

    .endWave {
        position: absolute;
        z-index: -1;
        width: 100%;
        fill: var(--light-color);
    }

    .atAGlance {
        text-align: center;
        margin-bottom: 50px;

        .downArrow {
            height: .7em;
        }

        .flex {
            flex-direction: row;
            justify-content: center;

            .item {
                width: 250px;
                margin: 30px;

                img {
                    width: 100px;
                    -webkit-filter: drop-shadow(1px 2px 2px #dddddd);
                    filter: drop-shadow(1px 2px 2px #dddddd);
                }
        
                h4 {
                    margin-top: 20px;
                    font-size: 1.4rem;
                }
        
                p {
                    font-size: 14px;
                }
        
                ul {
                    text-align: left;
                    font-size: 18px;

                    li {
                        line-height: 130%;
                    }
                }

                a {
                    font-weight: bold;
                    color: black;
                }
            }
        }

        .arrow {
            position: absolute;
            margin-top: 35px;
            max-width: 1100px;
        }
    }

    .preparationSection {
        background-color: var(--light-color);
        padding-bottom: 50px;

        .projectTypes {
            flex-wrap: wrap;
    
            .projectType {
                display: flex;
                margin-bottom: 1rem;
            }
        }

        h4 {
            margin-top: 0;
            margin-bottom: 0;
            font-size: 1.25rem;
        }

        p {
            margin-top: 0.5em;
            
        }

        .resources {
            text-align: center;
            padding: 30px;

            .button {
                margin: 10px;
            }
        }

        .caseDemo {
            display: flex;
            text-align: center;
            padding: 30px;
            gap: 30px;
        }
    }

    .how {
        padding-top: 20px;
    }

    .timelineSection {
        margin-top: 100px;

        .timelinePhotos {
            display: inline-block;
            vertical-align: top;
            width: 38%;
    
            img {
                padding-top: 1em;
                width: 100%;
            }
        }

        .timelineContainer {
            position: relative;
            display: inline-block;
            vertical-align: top;

            .timeline {
                margin: 0;
                padding: 0;

                li:before,
                li:after {
                    content: " ";
                    display: table;
                }
                
                li:after {
                    clear: both;
                }
            
                li:last-child {
                    margin-bottom: 0;
                }
        
                li {
                    position: relative;
                    padding: 0;
                    list-style: none;
                    margin-bottom: 50px;
        
                    h4 {
                        font-size: 25px;
                        margin: 10px 0; 
                        line-height: 28px;
                    }

                    p {
                        margin: .5em 0;
                        line-height: 1.5em;
                    }
            
                    .zoomLink {
                        img {
                            width: 30px;
                            margin-right: 10px;
                        }
                        background-color: white;
                        margin-bottom: .5em;
                        margin-top: .5em;
                        margin-right: 1em;
                        box-shadow: 0 2px 5px #dddddd;
                        text-decoration: none;
                        display: inline-flex;
                        flex-direction: row;
                        align-items: center;
                        -webkit-animation: fadein 2.5s; /* Safari, Chrome and Opera > 12.1 */
                        -moz-animation: fadein 2.5s; /* Firefox < 16 */
                        -ms-animation: fadein 2.5s; /* Internet Explorer */
                        -o-animation: fadein 2.5s; /* Opera < 12.1 */
                        animation: fadein 2.5s;
                        -webkit-transition: all 250ms;
                        border-radius: 10px;
                        padding: 7px 15px;
                    }
                
                    .zoomLink:hover {
                        -webkit-animation: fadein 2.5s; /* Safari, Chrome and Opera > 12.1 */
                        -moz-animation: fadein 2.5s; /* Firefox < 16 */
                        -ms-animation: fadein 2.5s; /* Internet Explorer */
                        -o-animation: fadein 2.5s; /* Opera < 12.1 */
                        animation: fadein 2.5s;
                        -webkit-transition: all 250ms;
                        transform: translateY(-5px);
                        box-shadow: 0 2px 10px #ccd3e6;
                    }

                    .notAvailable {
                        color: #aaaaaa;
                    }
                }
            }
        }
    }
}

@media (min-width: 1300px) {
    .blob1 {
        right: 290px;
    }

    .blob2 {
        right: 130px;
    }

    .bigBlob {
        svg {
            viewBox: 0 0 550 572; 
        }
    }
}

@media (min-width: 1100px) and (max-width: 1300px) {
    .blob1 {
        right: 200px;
    }

    .blob2 {
        right: 50px;
    }

    .bigBlob {
        svg {
            viewBox: 0 0 450 572; 
        }
    }
}

@media (min-width: 1100px) {
    .applyPage {
        .hero.container {
            min-height: 350px;
        }

        .timelineContainer {
            width: 50%;
            margin-left: 50px;

            .timeline:before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                width: 2px;
                margin-left: -1.5px;
                background-color: #e1e1e1;
            }

            .timeline {
                .content {
                    margin-left: 30px;
                }
            }
        }

        .timelineContainer:before{
            content: "";
            position: absolute;
            top: -8px;
            width: 10px;
            height: 10px;
            background-color: #e1e1e1;
            /* left: 50%; */
            margin-left: -5.5px;
            -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                    transform: rotate(45deg);
        }
        
        .timelineContainer:after{
            content: "";
            position: absolute;
            bottom: -8px;
            width: 10px;
            height: 10px;
            background-color: #e1e1e1;
            /* left: 50%; */
            margin-left: -5.5px;
            -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                    transform: rotate(45deg);
        }

        .projectTypes {    
            margin-top: 3rem;
            .projectType {
                width: 35%;
                margin-right: 5%;
                margin-left: 5%;
                flex-direction: row;
        
                .leftImage {
                    width: 20%;
                    margin-right: 30px;
                    img {
                        width: 100%;
                    }
                }

                .rightProjectDescription {
                    width: 80%;
                }
            }
        }
    }
}

@media (max-width: 1100px) {
    .applyPage {
        .timelineSection {
            .timelinePhotos {
                display: none;
            }
        }

        .preparationSection {
            .quickLinks {
                text-align: center;
                .description {
                    font-size: 14px;
                    margin-bottom: 0.5em;
                    margin-top: 1em;
                }

                .zoomLink {
                    img {
                        width: 30px;
                        margin-right: 10px;
                    }

                    p {
                        margin: 10px;
                        text-align: left;
                    }
                    
                    background-color: white;
                    margin: 10px;
                    box-shadow: 0 2px 5px #dddddd;
                    text-decoration: none;
                    display: inline-flex;
                    flex-direction: row;
                    align-items: center;
                    -webkit-animation: fadein 2.5s; /* Safari, Chrome and Opera > 12.1 */
                    -moz-animation: fadein 2.5s; /* Firefox < 16 */
                    -ms-animation: fadein 2.5s; /* Internet Explorer */
                    -o-animation: fadein 2.5s; /* Opera < 12.1 */
                    animation: fadein 2.5s;
                    -webkit-transition: all 250ms;
                    border-radius: 10px;
                    padding: 5px 10px;
                }
            }
        }

        .projectTypes {  
            text-align: center;
  
            .projectType {
                width: 100%;
                flex-direction: column;
                align-items: center;
        
                .leftImage {
                    width: 30%;
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}