/* All this css is very hacky and not mobile responsive yet */

footer {
    margin: 0;
    text-align: center;
    display: flex;
    font-family: "Avenir", sans-serif;
    font-style: normal;
    flex-shrink: 1;
    position: relative;
    color: #000000;
    width: 100%;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;    

    .footerImage {
        width: 200px;
    }
    
    .footerRight {
        text-align: left;
        margin: 0;
        flex-direction: row;
        display: flex;
    }
    
    .footerSection {
        width: 30%;
    }

    .email {
        width: 40%;

        a {
            text-decoration: underline;
            color: #000000;
        }

        .dontbreak {
            white-space: nowrap;
        }
    }
    
    .footerTitle {
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 0.5em;
        font-family:  "Lato", serif, "Source Serif Pro";
    }
    
    .footerLink {
        text-decoration: none;
        display: block;
        margin: 10px 0;
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        color: #000000;
    }

    .socialSection {
        display: flex;

        .socialIcon {
        width: 25px;
        padding: 3px;
        }
    }
}

@media (min-width: 900px) {
    footer {
        flex-direction: row;
        padding: 50px 70px 100px 70px;
        
        p {
            margin-block-start: 0em;
        }

        .footerLeft {
            text-align: left;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            
            .copyrightText {
                font-size: 12px;
            }
        }

        .footerRight {
            margin-left: auto;

            .footerSection {
                padding-left: 2em;
                padding-right: 2em;
            }

            .socialSection {
                flex-direction: column;
                width: 10%;
                margin-left: 2em;
                margin-right: 30px;
            }
        }
    }
}

@media (max-width: 900px) {
    footer {
        flex-direction: column;
        padding: 50px 0;

        .footerLeft {
            width: 100%;
            text-align: center;

            .copyrightText {
                display: none;
            }
        }

        .footerRight {
            margin: 0 auto;
            flex-direction: column-reverse;

            .footerSection {
                display: none;
                
            }
    
            .socialSection {
                padding-top: 10px;
                flex-direction: row;
                display: flex;
                width: 100%;
                margin: 0 auto;
                justify-content: center;
            }

            .email {
                text-align: center;
                margin: 0 auto;
                display: block;
                width: 100%;
                
                .footerTitle {
                    display: none;
                }
                
            }
        }
    }
}
