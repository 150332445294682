.impactPage {
            overflow-x: hidden;
            

    p {
        color: #333;
        line-height: 149.682%; /* 29.936px */

    }

    .hero {
        padding-bottom: 0px;

        p {
            line-height: 30px;
            
        }


        opacity: 0;
        transform: translateY(-50px);
        transition: opacity 0.5s;

            overflow-x: hidden;
        
    }
    .splashWave {
        position: absolute;
        top: 320px;
        left: 0px;
        z-index: -1;
        height: 100vh;
    }

    .rightWave {
        position: absolute;
        top: -100px;
        right: -400px;
        z-index: -5;
        overflow: hidden;
    }   
    

    .scholarshipSection {
        background-color: var(--light-color);

        p {
            color: #000;
            font-family: Lato;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 149.682%; /* 29.936px */
            letter-spacing: -0.1px;
        }

        .left {
            padding-right: 50px;
            .smallText {
                display: block;
                margin-top: 30px;

                p {
                    color: #b7b7b7;
                    display: inline;
                }

                img {
                    height: 28px;
                    display: inline;
                    position: relative;
                    left: 11px;
                    top: 7px;
                    transition: 0.1s all ease;
                    opacity: 0.7;

                    &:hover {
                        opacity: 0.85;
                    }
                }
            }

            
        }

        .right {

            .scholarText {
                position: relative;
                left: 30px;
                padding-bottom: 15px;
            }

            h2 {
                color: #1B67B1;
                font-family: Avenir;
                font-size: 36px;
                font-style: normal;
                font-weight: 900;
                line-height: normal;
                margin: 0;
            }
            h3 {
                color: #000;
                font-family: Lato;
                font-size: 20px;
                font-style: italic;
                font-weight: 400;
                line-height: 149.682%; /* 29.936px */
                letter-spacing: -0.1px;
                margin: 0;
            }
            img {
                max-width: 480px;
                margin: 0 auto;
            }
        }
        

    }

    .mauiSection {
        background-color: var(--light-color);
        text-align: center;
        padding-top: 1px;
        padding-bottom: 50px;

        .mauiPictures {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-column-gap: 10px;
            width: 70%;
            margin: 0 auto;

            img {
                width: 100%;
                border-radius: 5px;
            }
        }

        .mauiContent {
            width: 70%;
            margin: 0 auto;

            .blue {
                color: #1B67B1;
            }

            .mauiLogoButton {
                display: flex;
                justify-content: center;
                flex-direction: row;
                align-items: flex-start;
                gap: 20px;

                .cnha {
                    display: inline-block;
                    width: 140px;
                }

                a {
                    position: relative;
                    top: 8px;
                }


            }
        }
        
    }



    .diversitySection {
        text-align: center;
        background-color: var(--light-color);
        padding-top: 1px;
        padding-bottom: 140px;
        
        .diversityNumbers {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-column-gap: 30px;
            margin-top: 50px;

            .num {

                .numBox {
                    display: block;
                    border-radius: 5px;
                    padding: 20px;
                    font-size: 20px;
                    color: #1B67B1;
                    border: 2px solid #5EC3EF;
                    background: #FFF;
                    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
                    transition: transform 1s ease; /* Add a smooth transition effect */
                    perspective: 1000px; /* Set the perspective for the 3D effect */
                    transform-style: preserve-3d; /* Preserve the 3D transformations for child elements */
                    transform-origin: center bottom; /* Set the origin for rotation */
                  
                    

                    font-family: Lato;
                    font-size: 36px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                p {
                    color: #000;

                    text-align: center;
                    font-family: Lato;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }

        .diversityGraphic {
            height: 290px;
            position: relative;

            img {
                opacity: 0.99;
                // transition: transform 2s ease-in-out;
                transition: all 0.3s ease-in-out;
                // animation: floatAnimation 5s infinite alternate ease-in-out;

                // &:hover {
                //     transform: translate(10px, 10px);

                // }
            }

            .blobs {
                position: relative;
                display: block;
                width: 100%;
                bottom: 410px;
                
            }

            .div1 {
                position: absolute;
                top: 38px;
                left: 11%;
                width: 220px;
            }

            .div2 {
                position: absolute;
                top: 130px;
                left: -3.4%;
                width: 250px;
            }

            .div3 {
                position: absolute;
                top: 169px;
                right: 0%;
                width: 200px;
            }

            .div4 {
                position: absolute;
                top: 60px;
                right: 11%;
                width: 210px;
            }

        }

        .earthGraphic {
            width: 400px; /* Set the desired width of the container using viewport width unit */
            // height: 400px; /* Set the desired height of the container using viewport width unit */
            height: 400px; /* The height is set to 0 to maintain the aspect ratio */
            margin: 0 auto;
            position: relative;
        }

        .globe {
            border: 2px solid #64C3EF;
            border-radius: 50%;
            height: 100%;
            overflow: hidden;
            position: relative;
            margin: 0 auto;
            transform: translate(-50%, -50%);
            transform: rotate(-15deg);
            // height: ;

            svg {
            height: 100%;
            animation: rotation 30s linear 0s infinite;
              
              
              path {
                stroke: #64C3EF;
              }
            }
          }
          
          @keyframes rotation {
            from {
              transform: translateX(0);
            }
            
            from {
              transform: translateX(-50%);
            }
          }



    }

    .projectSection {
        background-color: rgb(28, 167, 226);
        position: relative;
        text-align: center;
        z-index: 10;
        padding: 70px 0 240px 0;
        margin-bottom: -250px;


        .grid {
            display: flex;
            flex-direction: row;
            gap: 10px;
            justify-content: center;
            gap: 50px;
            align-content: flex-end;
            flex-wrap: nowrap;
            align-items: center;
            position: relative;
            top: 40px;

            img {
                width: 120px;
                height: auto;
            }
        }
    }

    .CTA {
        position: relative;
        z-index: 20;

    }

}
 
@media (min-width: 1100px) {
    .impactPage {
        .scholarshipSection {
            padding:  50px 0 70px 0;

            .grid {
                display: flex;
                flex-direction: row;
                justify-content: space-between;


                .left {
                    position: relative;


                }

                .right {
                    position: relative;
                    padding: 0px 15px;

                }

                .smallText {
                        color: gray;
                }

                h1 {
                    font-size: 48px;
                    margin-top: 0px;
                }
            }

            .diversityNumbers {
                grid-template-columns: 2fr 2fr 2fr;
                grid-column-gap: 30px;
                margin-top: 100px;
            }
        }
    }
}


@media (max-width: 1100px) {
    .impactPage {

        .rightWave {
            position: absolute;
            top: -90px;
        }   
        
        .scholarshipSection {
            padding: 50px 0;

            .left {
                padding-right: 0;

                .smallText {
                    p {
                        font-size: 16px;
                    }

                    img {
                        height: 24px;
                    }
                   
                }
            }

            .right {
                text-align: center;
                h2 {
                    font-size: 30px;
                }

                h3 {
                    font-size: 16px;
                }

                img {
                    width: 100%
                }

                .scholarText{
                    left: 0;
                    padding-top: 30px;
                }
            }

        }

        .mauiSection {
            .mauiPictures {
                width: 100%;
            }

            .mauiContent {
                width: 100%;
                margin: 0 auto;

                .mauiLogoButton  {
                    flex-direction: column;
                    align-items: center;
                }
            }

            
        }

        .diversitySection {
            .diversityNumbers {
                grid-template-columns: 1fr;
                grid-row-gap: 15px;
                margin-top: 40px;
                justify-items: center;

                .num {
                    width: 90%;
                    p {
                        font-size: 18px;
                    }

                    .numBox {
                        font-size: 30px;
                    }
                }
            }

            .diversityGraphic {
                grid-column-gap: 20px;
                grid-row-gap: 15px;
                margin-top: 40px;
                justify-items: center;

                .blobs {
                    display: none;
                }

                .earthGraphic {
                    right: 35px;
                }
            }
            
        }
        


    }
}
