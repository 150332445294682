.homePage {
    h1 {
        font-family: 'Lato', serif,'Source Serif Pro',;
        font-size: 2rem;
    }

    p {
        font-family: 'Lato';
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 173.68%;
        letter-spacing: -0.005em;
        color: #212121;
    }

    .splash {
        margin: 0 auto;
        padding: 150px 0 200px 0;
        position: relative;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .container {
            max-width: 500px;
            width: 100%;
        }

            h1 {
                color: #000;
                text-align: center;
                font-family: Lato;
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: 113.182%; /* 54.327px */
                letter-spacing: -0.24px;
                margin-bottom: 0;
            }

            p {
                color: #212121;
                font-family: Lato;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 173.682%; /* 41.684px */
                letter-spacing: -0.12px;
                margin-top: 10px;
            }

        .blobsSplash {
            position: relative;
            // display: block;
            margin: 0;
            width: 100%;
            z-index: -10;
            bottom: 140px;
            animation: floatAnimation 15s ease-in-out infinite;

            svg, img {
                opacity: 0;
                transform-origin: center;
                transform: translateY(-50px);
                transition: opacity 0.5s
            }

            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            img {
                
                position: absolute;
                top: 50%;
                transform: translate(-50%, 0%);
                // animation: floatAnimation 15s ease-in-out infinite;
                transform-origin: center;
                
            }

            

            .topLeftWave {
                left: -20%;
                top: -700px;
                animation: spin 100s linear infinite;
                z-index: -100;
            }

            .lilblue {
                left: -3%;
                top: -220px;
                animation: floatAnimation 7s ease-in-out infinite;
                transform-origin: center;

            }

            @keyframes floatAnimation {
                0% {
                  transform: translate(0, 0);
                }
                25% {
                  transform: translate(2px, 1px);
                }
                50% {
                  transform: translate(-1px, 2px);
                }
                75% {
                  transform: translate(2px, -1px);
                }
                100% {
                  transform: translate(0, 0);
                }
              }

            .blgray {
                left: 0%;
                top: 300px;
            }

            .tlgray {
                left: 99%;
                top: -200px;
            }

            .tlbluecircle {
                left: 80%;
                top: -600px;
                z-index: -100;
                animation: spin 50s linear infinite;
            }

            @keyframes spin {
                0% {
                  transform: rotate(0deg);
                }
                100% {
                  transform: rotate(360deg);
                }
              }
            
            .blgrayblob {
                left: 99%;
                top: 200px;
                z-index: -120;
            }

            .blblueblob {
                left: 110%;
                top: 200px;
                z-index: -110;
            }

            
            .darkblueWave {
                top: 10px;
            }

            .lightblueWave {
                
            }
            
            .splashBlob1 {
                width: 355px;
                left: 28%;
                top: -340px;
            }

            .splashBlob2 {
                width: 345px;
                right: 0%;
                top: -340px;
            }

            .splashBlob3 {
                width: 550px;
                right: -5%;
                top: -70px;
            }

            .splashBlob4 {
                width: 385px;
                left: 26%;
                top: -10px;
            }
        }
           
    }

    .inspired {
        background-color: var(--light-color);
        position: relative;
        display: block;

        h1, p {
            margin-bottom: 15px;
        }

        a {
            margin-top: 10px;
        }

        .blobs {
            .rope {
                position: absolute;
                top: -200px;
                left: 0px;
                stroke: var(--dark-color);
            }

            .bottomLeft {
                top: 250px;
                left: 130px;
                position: absolute;
                z-index: 2;
            }

            .topRight {
                top: -70px;
                left: 460px;
                position: absolute;
                z-index: 3;
            }

            .exec {
                top: 170px;
                width: 310px;
                left: 240px;
                position: absolute;
                z-index: 1;
                filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
            }

            .railroad {
                top: 0px;
                left: 130px;
                width: 400px;
                position: absolute;
                z-index: 0;
            }
        }

        .clear {
            clear: both;
        }
    }

    .spectrums {
        text-align: center;
        padding: 60px 0;

        .spectrumText {
            h1 {
                margin: 20px;
            }
        }

        .button {
            margin-left: 10px;
            margin-right: 10px;
            display: inline-block;
            vertical-align: middle;

        }

        .mobileRow {
            display: block;

            img {
                width: 25px;
                margin: 10px;
            }
        }

        .projectDomain {
            display: inline-block;
            vertical-align: middle;
            border: 1px solid rgba(214,214,214,0.5);
            padding: 15px 20px;
            border-radius: 100px;
            box-sizing: border-box;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.10);
            overflow: visible;
            margin: 10px 10px;
            cursor: default;

            img {
                height: 25px;
                display: inline-block;
                margin-right:15px;
                vertical-align: middle;
            }

            p {
                font-size: 15px;
                display: inline-block;
                font-weight: bold;
                position: relative;
                margin: 0;
                vertical-align: middle;
            }
        }
    }

    .partners {
        text-align:center;
        background-color: var(--light-color); // $light-blue;

        .container {
            padding: 50px 0 100px 0;
            max-width: 1300px;

            h1 {
                margin-top: 0;
            }

            img {
                display: inline-block;
                vertical-align: middle;
            }
        }
    }

    .wave {
        z-index: -1000;
    }
}

@media (min-width: 1100px) {
    .homePage {
        h1 {
            font-size: 3rem;
            line-height: 113.18%;
        }

        .inspired {
            .container {
                display: grid;
                grid-template-columns: 2fr 2fr;
            }

            .right {
                text-align: right;
                display: block;
                margin-bottom: 100px;
            }
        }

        .spectrums {
            .container p {
                padding: 0 100px 0px 100px;
            }
        }

        .partners {
            .container {
                h1 {
                    margin-bottom: 1em;
                }

                img {
                    padding-left: 1%;
                    padding-right: 1%;
                    width: 13%;
                }
            }
        }

        .who {
            .grid.container {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 30px;
                padding-top: 100px;
                text-align: right;
                img {
                    width: 110%;
                    z-index: -1000;
                    // text-align: center;
                }
            }
        }
        .topsvg {
            margin-top: -180px;
        }
    }
}

// ~ MOBILE ~

@media (max-width: 1100px) {
    .homePage {
        .splash {
            padding: 30px 0;
            position: relative;
            bottom: 20px;
                    

            .mobileLogo {
                width: 60%;
            }

            h1 {
                margin: 10px 20px;
                font-size: 38px;
            }

            p {
                // font-size: 18px;
                margin: 20px 20px;
                line-height: 133.682%;
            }

            .bigBlob, .miniBlob, .seniors {
                display: none;
            }

            .emgajen {
                // width: 80%;
                margin: 0 auto;
            }

            .blobsSplash {
                backdrop-filter: blur(4px);

                svg {
                    display: none;
                }

                img {
                    display: none;
                }

               
                // .blblueblob {
                //     display:flex !important;
                //     left: 130%;
                // }

                // .topLeftWave {
                //     display:flex !important;
                //     left: -50%;
                //     top: -450px;
                //     opacity: 0.1;
                // }
            }
        }

        .inspired {
            padding: 20px 0;
            text-align: center;

            .showOnMobile {
                margin: 10%;
                .hillpic {
                    width: 100%;
                }
            }

        }

        .spectrums {

            .projectDomain {
                margin: 5px;
            }
            .row {
                padding: 0;
            }

            .button {
                margin-bottom: 25px;
            }
        }

        h1 {
            font-size: 30px;
            margin: 40px 0;
        }

        p {
            font-size: 20px;
        }

        .blobs {
            display: none;
        }

        .partners {
            .container {

                h1 {
                    margin-bottom: .5em;
                }

                img {
                    padding-left: 2%;
                    padding-right: 2%;
                    width: 26%;
                }
            }
        }

        .who {
            .grid.container {
                text-align: center;
                img {
                    width: 90%;
                    max-width: 500px;
                }
            }
        }
    }
    .topsvg {
      margin-top: 0px;
    }
}
