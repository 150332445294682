.membersPage {
    .membersHero  {
        padding: 50px 0 0 0;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        text-align: center;
        overflow-x: hidden;

        opacity: 0;
        transition: opacity 0.5s;


        h1 {
            margin: 0 auto 50px auto;
            font-weight: 700
        }

        .carousel-wrapper {
            // transform: rotate(15deg);
            position: relative;
            // z-index: -50;
        }

        .photo-carousel {
            display: flex;
            flex-direction: row;
            position: relative;
            padding-left: 10%;
            padding-bottom: 10px;
            transition: all 800ms cubic-bezier(0.03, 0.98, 0.52, 0.99) 0s;
    
            .fade-in {
                opacity: 0;
                animation: fadeIn ease-in 0.5s;
              }
              
              @keyframes fadeIn {
                0% {
                  opacity: 0;
                }
                100% {
                  opacity: 1;
                }
              }

            img {
                height: 175px;
                border-radius: 30px;
                margin-right: 20px;
                width: auto;
            }
        }

        #carousel1 {
            transform: translateX(-200px)
        }

    }

    .memberSplashWave {
        position: absolute;
        top: 100px;
        left: 0;
        z-index: -100;
        width: 100%;
    }

    .description {
        text-align: center;
        color: black;
        // padding-bottom: 20px;
    }

    .overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.7);
        transition: opacity 500ms;
        z-index: 998;
        display: flex;

        justify-content: center;
        align-items: center;
    }

    .bioContainer {
        margin: 70px;
        padding: 20px;
        background: #fff;
        opacity: 1;
        border-radius: 5px;
        width: 60%;
        min-height: 60%;
        max-height: 60%;
        overflow-y: auto;
        height: fit-content;
        z-index: 999;
        display: flex;
        justify-content: center;
        // align-items: center;

        .bioTitle {
            padding: 20px;
            width: 40%;
            height: 95%;

            text-align: center;
    
            .image {
                width: 100%;
            }
            .name {
                font-size: 25px;
            }
            .title {
                font-size: 18px;
            }
        }

        .bioContent {
            padding: 20px;
            padding-right: 10px;
            width: 60%;
            height: 100%;

            .close {
                width: 50px;
                float: right;
                background-color: transparent;
                border: none;
                text-align: right;
            }

            .icon {
                width: 30px;
            }

            .links {
                display: flex;
                gap: 10px;
            }
        }
    }

    .splashWaveLeft {
        position: absolute;
        top: 100px;
        left: 0;
        z-index: -1;
        fill: var(--light-color);
    }

    .splashWaveRight {
        position: absolute;
        top: 100px;
        right: 0;
        z-index: -1;
        fill: var(--light-color);
    }

    .nibVideo {
        text-align: center;
        p {
            margin-bottom: 30px;
        }
    }
    
    h1 {
        margin: 0 50px;
    }

    .splashWave {
        position: absolute;
        top: 900px;
        left: 0px;
        z-index: -1;
        width: 100%;
    }


    .memberGallery {
        padding-top: 50px;
        background-color: var(--light-color);
        .tapQueue {
            text-align: center;
            .abiTest{
                width: 75%;
            }
        
            .tapOn {
                color: #1B67B1;
                text-align: center;
             
                align-content: center;
                margin: auto;
            }
        }

        h2, p {
            margin-left: 1%;
        }
    }

    .advisor {
        background-color: var(--light-color);
        // text-align: center;
        img {
            width: 90%;
        }
        h2 {
            margin-bottom: 0;
        }
        p {
            text-align: left;
            line-height: 24px;
        }
    }

    .invertedWave {
        width: 100%;
    }

    .carousel {
        background-color: var(--light-color);
        padding-bottom: 40px;
        padding: 100px 0 100px 0;

        .carouselView {
            display: block;
            text-align: center;
            position: relative;
        }

        // changing max-width here will adjust width of carousel
        .container {
            padding: 0;
            max-width: 90%;
        }
    }

    .alumniSection {
        img {
            width: 100%;
        }

        .company-logos img {
            object-fit: contain;
            vertical-align:middle;
            margin-bottom: 25px;
            width: 10%;
            margin-left: 3%;
            margin-right: 3%;
        }

        p {
            line-height: 26px;
            font-size: 18px;
        }
    }
}

@media (min-width: 1100px) {
    .membersPage {

        .alumniSection {
            .grid {
                display: grid;
                grid-template-columns: 3fr 4fr;
                grid-column-gap: 40px;
                margin-bottom: 100px;
            }
        }

        .advisor {
            padding-top: 50px;
            padding-bottom: 20px;
            .grid {
                display: grid;
                margin-right: 10%;
                margin-left: 10%;
                grid-template-columns: 3fr 4fr;
                grid-column-gap: 40px;
                margin-bottom: 100px;
            }
        }
    }
}

@media (max-width: 1100px) {
    .membersPage {

        .photo-carousel {
            // transform: translateX(0) !important;
            // overflow-x: scroll;
        }

        #carousel2 {
            // transform: translateX(600px);
            // display: none;
        }

        .membersHero {
            background-color: var(--light-color);

            .container {
                padding: 50px 0;
            }
        }

        h2 {
            font-size: 24px;
        }
        
        .alumniSection, .advisor {
            padding-top: 50px;
            .grid {
                h2 {
                    margin-bottom: 0;
                }
            }
        }

        .advisor {
            padding-bottom: 50px;
            img {
                width: 80%;
            }
        }
    }
}

@media (max-width: 700px) {
    .membersPage {
        .alumniSection {
            .company-logos img {
                width: 25%;
                margin-left: 3%;
                margin-right: 3%;
            }
        }
    }
}