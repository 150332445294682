.projectsPage {

    .hero {
        p {
            line-height: 28px;
        }

        opacity: 0;
        transform: translateY(-50px);
        transition: opacity 0.5s;

        display: grid;
        grid-template-columns: 5fr 5fr;
        grid-column-gap: 50px;
        position: relative;
    }

    .splashWave {
        position: absolute;
        top: 270px;
        left: 0px;
        z-index: -1;
        width: 100%;
    }

    .projectTypesSection {
        background-color: var(--light-color);
        padding-top: 50px;
        padding-bottom: 100px;

        .projectTypes {
            flex-wrap: wrap;

            .projectType {
                display: flex;
            }

            h4 {
                font-size: 1.25rem;
                margin-top: 0.6rem;
                margin-bottom: 0px;
            }

            p {
                line-height: 24px;
                font-size: 16px;
            }
        }
    }

    .waveAnchor {
        position: relative;
        // background-color: red;
        height: 10px;
        top: 270px;

    }

    .backgroundWaves {
        position: absolute;
        

        .thickblue {
            position: absolute;
            height: auto;
            width: 120%;
            right: 0%;
            animation: moveAndFluctuateMedium 10s infinite; /* Adjust the duration of the animation */
            animation-delay: -6.4s; /* Introduce a delay for a more spontaneous feel */

        }

        .thickblue2 {
            position: absolute;
            top: 10px;
            height: auto;
            width: 600%;
            right: -50%;
            margin: 0 auto;
            animation: moveAndFluctuateLonger 18s infinite; /* Adjust the duration of the animation */
            animation-delay: -12s; /* Introduce a delay for a more spontaneous feel */

        }

        .darkblue {
            position: absolute;
            height: auto;
            width: 120%;
            right: 0%;
            animation: moveAndFluctuateMedium 16s infinite; /* Adjust the duration of the animation */
            animation-delay: -4.4s; /* Introduce a delay for a more spontaneous feel */

        }

        .thinblue {
            position: relative;
            bottom: -10px;
            height: auto;
            right: 10%;
            width: 120%;
            margin: 0 auto;
            animation: moveAndFluctuate 24s infinite; /* Adjust the duration of the animation */
            animation-delay: -13s; /* Introduce a delay for a more spontaneous feel */

        }

        @keyframes moveAndFluctuate {
            0%, 100% {
              transform: translate(0, 0);
            }
            50% {
              transform: translate(100px, 20px); /* Adjust the values for horizontal and vertical movement */
            }
        }

        @keyframes moveAndFluctuateLonger {
            0%, 100% {
              transform: translate(0, 0);
            }
            50% {
              transform: translate(800px, 5px); /* Adjust the values for horizontal and vertical movement */
            }
        }

        @keyframes moveAndFluctuateMedium {
            0%, 100% {
              transform: translate(0, 0);
            }
            50% {
              transform: translate(100px, 15px); /* Adjust the values for horizontal and vertical movement */
            }
        }
          
          .thinblue {
          }
          
          .wave-path {
            transition: transform 0.5s ease; /* Optional: add smooth transition between animations */
          }
          
          
    }

    .projectSpectrumSection {
        margin-top: 100px;
        img {
            display: inline-block;
            width: 90px;
        }

        p {
            line-height: 30px;
            margin-top: 50px;
            margin-bottom: 50px;
        }

        .clientTypes {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 20px;
        }

        .clientTypeBox {
            border-radius: 12px;
            border: 1px solid #E6E6E6;
            background: rgba(255, 255, 255, 0.8);
            box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
            backdrop-filter: blur(4px);
            margin: 25px;
            width: 100%;
            padding: 20px 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            transition: all 0.3s ease;


            &:hover {
                background: rgba(255, 255, 255, 0.88);
                backdrop-filter: blur(5px);
                transform: translateY(-5px);
                box-shadow: 0 13px 27px -5px rgba(50,50,93,.3), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025);
                cursor: pointer;
                    /* Adjust the rotation and translation values based on your preference */

                .arrow {
                    img {
                        transform: translateX(2px);

                    }
                }
            }

            

            h2 {
                color: #333;
                text-align: center;
                font-family: Lato;
                font-size: 19px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 10px 0 30px 0px;
                text-align: center;
            }

            b {
                font-weight: 700;
                color: #1B67B1;
            }

            p {
                color: #000;
                font-family: Lato;
                font-size: 15px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-align: center;
                margin: 40px 0 10px 0;

                .arrow {
                    img {
                        padding-left: 3px;
                        width: 13px;
                        transition: 0.5s all ease;
                    }
                }
            }

            .clientLogos {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                align-items: center;
                justify-items: center;
                width: 100%;
                grid-gap: 10px; /* Adjust the gap between images as needed */
            }

            .full-width {
                grid-column: span 2; /* Span 2 columns for the third item */
                grid-row: 2; /* Place it in the second row */
              }
        }

    }
}

.breakdownModal {
    display: block;
    margin: 0 auto;
    width: 800px;
    height: 650px;
    margin-top: 70px;
    background-color: white;
    border-radius: 5px;
    overflow-y: auto;

    .topSection {
        padding: 20px 60px;
        background-color: #F1FEFF;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        h1 {
            color: #111;
            font-family: Lato;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.18px;
        }

        p {
            color: #333;
            font-family: Lato;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.1px;
        }

        img {
            height: 90px;
        }
    }

    .projectHeader {
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-column-gap: 30px;
        padding: 10px 40px;


        img {
            width: 80px;
            margin: auto;
        }

        h2 {
            color: #000;
            font-family: Lato;
            font-size: 27px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.135px;
        }
    }

    .projectDescription {
        padding: 0px 40px 5px 40px;
        position: relative;
        bottom: 20px;
    }

    &:focus-visible {
        outline: none !important;
    }
}

.MuiModal-root .css-79ws1d-MuiModal-root {
    overflow: scroll !important;
}

@media (min-width: 1100px) {
    .projectsPage {
        .projectTypes {
            margin-top: 3rem;
            .projectType {
                width: 40%;
                margin-right: 5%;
                margin-left: 5%;
                flex-direction: row;

                .leftImage {
                    width: 20%;
                    margin-right: 30px;
                    img {
                        width: 100%;
                    }
                }

                .rightProjectDescription {
                    width: 80%;
                }
            }
        }

        .clientsSection img {
            width: 11%;
            margin-left:0%;
            margin-right: 2.3%;
            margin-bottom: 25px;
            vertical-align: middle;
        }
    }
}

@media (max-width: 1100px) {
    .projectsPage {
        // overflow-x: hidden;

        .hero {
            grid-template-columns: 5fr;
        }
        .projectTypes {
            text-align: center;

            .projectType {
                width: 100%;
                flex-direction: column;
                align-items: center;

                .leftImage {
                    width: 30%;
                    img {
                        width: 100%;
                    }
                }
            }
        }

        .waveAnchor {
            overflow-x: clip;
        }

        .projectSpectrumSection {

            .clientTypes {
                flex-direction: column;

                .clientTypeBox {
                    margin: 0;
                    padding: 20px 0px;
                    background: rgba(255, 255, 255, 0.9);
                }
            }


            
        }

        .mobileTimeline {
            .grid {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 30px;
                img {
                    margin: 5px;
                    max-height: 50px;
                    width: auto;
                    max-width: 100px;
                }
            }
        }

        .clientsSection img {
            width: 30%;
            margin-left:0%;
            margin-right: 2.3%;
            margin-bottom: 25px;
            vertical-align: middle;
        }

        .backgroundWaves {
            transform: rotate(131deg);
            right: 0px;
            position: relative;
            right: 170px;
            top: 320px;

            .thickblue {
                width: 400%;
                right: 0%;

            }

            .darkblue {
                position: absolute;
                height: auto;
                width: 500%;
                right: 0%;
            }

            .thinblue {
                position: relative;
                bottom: -10px;
                height: auto;
                /* width: 400%; */
                right: 150%;
                width: 540%;
            }

            .thickblue2 {
                position: absolute;
                top: 10px;
                height: auto;
                width: 3000%;
                right: -50%;
            }
    }

   
}

.breakdownModal {
    width: 90%;

    .topSection {
        padding: 15px 30px;

        h1 {
            font-size: 1.3rem;
            margin-bottom: 10px;
            margin-top: 10px;
        }

        p {
            font-size: 1rem;
            margin: 0;
        }
    }
}
}

@media (min-width: 1440px) {
    .projectsPage .waveAnchor {
        top: 244px;
    }

    .backgroundWaves {
        width: 100%;
    }
}
