.questions {

    h2 {
        text-align:center;
        font-size: 64px;
        line-height: 113.18%;
        margin: 80px 0;
    }

    h4 {
        font-size: 24px;
        font-family: 'Open Sans', sans-serif;
        color: black;
        letter-spacing: 0;
    }
}
