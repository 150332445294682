:root {
    --menu-max-height: 300px;
    --fade-from-color: transparent;
    --fade-to-color: black;
    --timeout: 500ms;
}

.nav {
    margin: 0;
    font-family: 'Open Sans', 'Avenir', sans-serif;
    font-weight: 600;

    font-size: 15px;
    text-align: left;
    position: relative;
    z-index: 1000;


    ul {
        list-style: none;
        margin: 0;
        overflow: hidden;
        padding: 0;
        margin: auto 0;
        position: relative;
        top: 10px
    }

    li {
        display: inline;
        clear: left;
        position: relative;
        color: #272727;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        transition: all 400ms cubic-bezier(0.03, 0.98, 0.52, 0.99) 0s;

       

       
    }

    

    .navbar { // desktop

        border-radius: 6px;
        border: 1px solid #E3E3E3;
        background: rgba(255, 255, 255, 0.80);
        box-shadow: 0px 2px 5px 1px rgba(10, 10, 10, 0.10);
        backdrop-filter: blur(3px);
        margin: 35px auto 0px auto;
        width: 82%;
        height: 87px;
        max-width: 1250px;
        z-index: 1000;

        .container {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        a {
            transition: 0.1s all ease;
        }

        img {
            height: 60px;
            position: relative;
            top: 3px;
        }

        .logo {
            display: inline-block;
            // opacity: 1 !important;
            transition: all 1600ms cubic-bezier(0.03, 0.98, 0.52, 0.99) 0s;

    
    
            &:hover {
                transform: scale(1);
                transform: translateX(5px)
            }
        }

        .right {
            float: right;
            position: relative;
            top: 20px;

            li {
                opacity: 0.8;

                &:hover {
                    opacity: 1;
                }

                &:last-child {
                    a {
                        color: #3380C4 !important;
                    }
                    
                }
            }

            
        }
    }

    .tab, .tab:hover {
        display: inline-block;
        vertical-align: middle;
        margin: 0 15px;
        text-decoration: none;
    }
    
    .tab, .tab:hover {
        color: #272727;
    }

    .tab:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 100%;
    }

    .mobileNav {
        display: none;
    }
}


@media (max-width: 1100px) {
    .nav {
        .navbar {
            display: none !important;
            height: 0;
            box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
            background: #FFFFFF;


        }
        .mobileNav {
            display: block;
            width: 100vw;
        } 

        .tab, .tab:hover {
            color: #163760;
            text-decoration: none;
            font-weight: 500;
        }

        .burgerIcon {
            width: 30px;
            height: 35px;
            margin-left: auto;
            margin-right: 20px;
            margin-top: 20px;
            z-index: 150;
        }

        .mobileList {
            background-color: white;
            z-index: 100;
            width: 100vw;
            box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
            position: absolute;
            left: 0;
            padding: 0;

            li {
                margin: 15px;
                display: block;
            }
        }

        .display-enter {
            max-height: 0;
        }

        .display-enter-active {
            max-height: var(--menu-max-height);
            transition: all var(--timeout);
        }

        .display-exit {
            max-height: var(--menu-max-height);
        }

        .display-exit-active {
            max-height: 0;
            transition: all var(--timeout);
        }

        .display-exit-done {
            max-height: 0;
            padding: 0;
            margin-top: 0;
        }
    }
}