
:root {
    /* Set the deafult values */
    --medium-color: #91e9ff; // change to #91e9ff ;)
    --light-color: #F0FCFE; // change to #F0FCFE
    --dark-color: #1579BC;// change to #1579BC
    --button-color: #1579BC; // change to #1579BC
    --button-color-hover: #146aa3;
}

body {
    margin: 0;
    font-family: 'Lato', 'Segoe UI', 'Roboto', 'Oxygen', sans-serif;
    font-style: normal;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 100%;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

h1 {
    font-family: 'Lato', 'Source Serif Pro', serif;
    font-size: 46px;
    font-weight: 400;
    color: #000000;
    margin: 20px 0;
}

h2 {
    font-family: 'Lato','Source Serif Pro', serif;
    font-size: 36px;
    font-style: normal;
    font-weight: bold;
    color: #000000;
    margin: 20px 0;
}

h3 {
    font-size: 20px;
    letter-spacing: 0.1em;
    color: #1B67B1;
}

p {
    font-size: 18px;
    color: #000000;
}

.container {
    margin: 0 auto;
    max-width: 1100px;
    position: relative;
}

section {
    margin: 0 auto;
}

.hero {
    margin: 0 auto;
    padding: 100px 0;

    h1 {
        margin: 10px 0;
        font-weight: 700;
    }

    h3 {
        margin: 0;
    }

    .heroImage {
        max-width: 500px;

        img {
            width: 100%;
        }
    }
}

.wave {
    position: relative;
    top: 5px;
    z-index: -1;
    width: 100%;
    fill: var(--light-color);
}

.blob {
    fill: var(--medium-color);
}

.invertedWave {
    background-color: var(--light-color);
    width: 100%;
}

.flex {
    display: flex;
}

.verticallyCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.wip {
    color: blue;
    background-color: #fff781;
    display: inline-block;
}

.fadeOut{
    opacity:0;
    width:0;
    height:0;
    transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}

.fadeIn{
    opacity:1;
    transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}

@media (min-width: 1100px) {
    .hero {
        padding: 100px 0;
        display: grid;
        grid-template-columns: 3fr 2fr;
        grid-column-gap: 40px;
    }

    .showOnMobile {
        display: none;
    }

    .container {
       padding-left: 100px;
       padding-right: 100px;
    }

    .hideOnWeb {
        display: none !important;
    }

    /* Credit: https://gist.github.com/CodeMyUI/1742d679c031cfea06948681e24cefd4 */
    .underline-magical {
        background-image: linear-gradient(90deg, #5EC3EF, #9adbf7);
        background-repeat: no-repeat;
        background-size: 100% 0.2em;
        background-position: 0 88%;
        transition: background-size 0.25s ease-in;
    }
    
    .underline-magical:hover {
        background-size: 100% 88%;
        cursor: pointer;
        text-decoration: none;
        color: black;
    }
}

@media (max-width: 1100px) {
    .container {
        margin: 0 50px;
    }

    h1 {
        font-size: 2rem;
    }

    .hero {
        padding: 50px 0;
        
        .heroImage {
            padding-top: 30px;
            width: 100%;

            img {
                width: 100%;
            }
        }
    }

    .hideOnMobile {
        display: none !important;
    }
}