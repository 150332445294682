.redirectingPage {
    background-color: #F5F5F5;
    text-align: center;
    height: 90vh;

    img {
        height: auto;
        width: 70%;
        max-width: 500px;
    }

    h1 {
        margin-top: 0;
    }
}