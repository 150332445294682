.memberProfile {
    display: inline-block;
    position: relative;
    vertical-align: top;

    .memberImage, .memberSillyImage {
        width: 100%;

    }

    .memberSillyImage {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
        -webkit-transition: all 0.3s ease-in-out 0s;
        -moz-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
    }

    .name {
        text-align: center;
        width: 100%;

        h4, p {
            color: black;
        }

        p {
            font-size: 14px;
            margin-top: 0;
        }

        h4 {
            margin-bottom: 0.5em;
        }

        .icon {
            width: 15px;
        }
    }

    .links {
        display: flex;
        gap: 10px;
        justify-content: center;
    }
}

.clickable {
    cursor: pointer;
}


.memberProfile:hover {
    .memberSillyImage {
        opacity: 1;
    }
}

@media (min-width: 800px) {
    .memberProfile {
        height: 18%;
        width: 18%;
        margin: 1%;
    }
    // .memberProfile.execProfile {
    //     height: 14.5%;
    //     width: 14.5%;
    // }
}

@media (max-width: 800px) {
    .memberProfile {
        height: 40%;
        width: 40%;
        margin: 5%;
    }
}