.aboutPage {
    .hero {
        p {
            line-height: 30px;
        }

        opacity: 0;
        transform: translateY(-50px);
        transition: opacity 0.5s
    }
    .splashWave {
        position: absolute;
        top: 100px;
        left: 0px;
        z-index: -1;
        width: 100%;
    }

    .aboutUsSection {
        background-color: var(--light-color);

        p {
            line-height: 26px;
        }

        .grid {
            img {
                width: 100%;
                border-radius: 5px;
            }
        }
    }

    .statsSection {
        display: grid;

        h2 {
            font-family: 'Open Sans', sans-serif;
            font-size: 36px;
            font-weight: bold;
            color: var(--dark-color);
            margin: 0 0;
            text-align: center;
        }

        p {
            margin-top: 0px;
            text-align: center;
            line-height: 22px;
        }
    }

    .valuesSection {
        margin-top: 100px;

        h1 {
            margin-bottom: 50px;
        }

        .value {
            h2 {
                font-family: 'Open Sans', sans-serif;
                font-size: 1.25em;
                font-weight: bold;
                margin-top: 0;
                margin-bottom: 0.25em;
            }

            p {
                margin-top: 0;
                line-height: 22px;
            }
        }

        .right {
            img {
                width: 100%;
            }
        }
        
    }

    .globalSection {
        background-color: var(--light-color);
        padding: 30px 0 100px 0;

        p {
            line-height: 26px;
        }

        .grid {
            img {
                width: 100%;
                max-width: 400px;
            }

            .button {
                align-self: flex-start;
            }
        }
    }

    .cultureSection {
        padding-top: 20px;
        align-content: center;
        text-align: center;

        p {
            line-height: 26px;
            font-size: 16px;
        }

        .cultureTable {
            display: inline-block;
            width: 100%;
            margin: auto;
            margin-top: 30px;
            align-items: center;
        }

        .col {
            align-content: center;
            text-align: center;
            display: inline-block;

            img {
                width: 100%;
                height: auto;
            }
        }
    }
}

@media (min-width: 1100px) {
    .aboutPage {
        .aboutUsSection {
            padding: 100px 0;

            .grid {
                display: grid;
                grid-template-columns: 3fr 4fr;
                grid-column-gap: 40px;

                .left {
                    position: relative;
                }

                .right {
                    position: relative;
                    padding: 0px 15px;
                }

                h1 {
                    font-size: 48px;
                    margin-top: 0px;
                }
            }

            .statsSection {
                grid-template-columns: 2fr 2fr 2fr 2fr;
                grid-column-gap: 30px;
                margin-top: 100px;
            }
        }

        .valuesSection {
            .grid {
                display: grid;
                grid-template-columns: 4fr 3fr;

                .value {
                    display: grid;
                    grid-template-columns: 1fr 3fr;
                    grid-column-gap: 2em;
                    margin-bottom: 2rem;
                    margin-top: 10px;
                }

                .right {
                    margin-left: 4rem;

                    img {
                        width: 100%;
                    }
                }
            }
        }

        .globalSection {
            .grid {
                display: grid;
                grid-template-columns: 3fr 4fr;
                grid-column-gap: 40px;
            }

            h1 {
                text-align: center;
            }
        }

        .cultureSection {
            .col {
                width: 40%;
                margin-left: 5%;
                margin-right: 5%;
                height: 440px;
                vertical-align: top;

                p {
                    margin-left: 10%;
                    margin-right: 10%;
                }
                .lifeInNibPics {
                   text-align: center;
                }
            }
        }
    }
}


@media (max-width: 1100px) {
    .aboutPage {
        .aboutUsSection {
            padding: 50px 0;


            .statsSection {
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 30px;
                margin-top: 50px;
            }
        }

        .valuesSection {
            margin-bottom: 50px;
            text-align: center;

            h1 {
                margin-bottom: 50px;
            }

            .value {
                text-align: center;
                margin-bottom: 2rem;
                margin-top: 1rem;

                h2 {
                    margin-top: 1rem;
                }
            }

            .right {
                padding: 2rem;
            }
        }

        .globalSection {
            text-align: left;

            .showOnMobile {
                padding: 20px 20px 20px 0;

                img {
                    width: 100%;
                }
            }
        }

        .cultureSection {
            .col {
                margin: 0 5%;

                p {
                    margin-bottom: 30px;
                }
            }
        }
    }
}
