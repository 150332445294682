.bannerContainer {
    width: 100%;
    z-index: 50;

    .banner {
        display: flex;
        padding: 10px 20px;
        flex-direction: row;
        transition: all 0.5s;
        align-items: center;

        p {
            text-align: center;
            margin-left: 20px;
            line-height: 100%;
            font-size: 18px;
            letter-spacing: 0em;
        }

        .exit, .exit:hover {
            cursor: pointer;
            margin-left: auto;
            rect {
                fill: var(--dark-color);
            }
        }
    }
}

@media (min-width: 1100px) {
    .bannerContainer {
        position: absolute;
    
        .banner {
            display: flex;
            margin: 10px;
            background-color: var(--light-color);
            border-radius: 15px;
            flex-direction: row;
            box-shadow: 2px 2px #c4e5eb;
    
            p {
                margin-left: 20px;
                line-height: 100%;
                font-size: 18px;
                letter-spacing: 0em;
                width: 100%;
            }
    
            .exit, .exit:hover {
                width: 20px;
                cursor: pointer;
                // margin-left: auto;
            }
        }
    }
}

@media (max-width: 1100px) {
    .bannerContainer {
        left: 0;
        position: fixed;
        text-align: center;
        bottom: 0px;

        .banner {
            box-shadow: 0px 0px 20px rgba(196, 229, 235, 0.5);
            background-color: #0a378a;

            p, a {
                color: white;
                font-weight: bold;
            }
            
            .exit, .exit:hover {
                width: 70px;
                padding-left: 5px;
                rect {
                    fill: white;
                }
            }
        }
    }
}